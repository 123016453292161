import Bugsnag from '../bugsnag';
import {CustomElementAlreadyDefinedError} from '../utils';

/**
 * @returns {boolean} Whether the browser supports Web Components.
 */
export function isBrowserSupported(): boolean {
  return Boolean(window.customElements);
}

/**
 * Define a custom element (web component) if the browser supports custom elements and if it has not been
 * previously defined.
 * @param {string} name Name for the new custom element. This will be the distinct HTML tag in kebab-case
 * by which the element will be created. Note that custom element names must contain a hyphen.
 * @param {object} component Constructor for the new custom element.
 */
export function defineCustomElement(
  name: string,
  component: CustomElementConstructor,
) {
  // Return early if the browser doesn't support Web Components.
  if (!window.customElements) return;

  const existingConstructor = customElements.get(name);
  if (existingConstructor) {
    const componentVersion = (existingConstructor as any).componentVersion;

    if (componentVersion && componentVersion !== 'vanilla') {
      startBugsnag({
        bundle: name,
        // eslint-disable-next-line no-process-env
        bundleLocale: process.env.BUILD_LOCALE,
      });
      Bugsnag.notify(
        new CustomElementAlreadyDefinedError(
          `Custom element ${name} already registered by ${componentVersion}`,
        ),
      );
    }
    return;
  }

  Reflect.defineProperty?.(component, 'componentVersion', {value: 'vanilla'});
  customElements.define(name, component);
}

/**
 * Util for starting Bugsnag with the correct configuration. Deliberately put outside /common/bugsnag to
 * keep that as a pure Bugsnag client implementation.
 * @param {object} metadata Diagnostic metadata that you want to send with all captured events.
 */
export function startBugsnag(metadata = {}) {
  Bugsnag.start({metadata});
}

/**
 * Define the window.Shopify.SignInWithShop object if it doesn't exist.
 */
export function defineWindowSignInWithShop() {
  if (!window.Shopify) {
    window.Shopify = {};
  }

  if (!window.Shopify.SignInWithShop) {
    window.Shopify.SignInWithShop = {};
  }
}

/**
 * Adds an init function to the window.
 * @param {string} signInWithShopKey The key to be added to window.Shopify.SignInWithShop
 * @param {Function} initFunction The corresponding init function
 */
export function defineInitFunction(
  signInWithShopKey: string,
  initFunction: (arg0: any) => any,
) {
  defineWindowSignInWithShop();

  window.Shopify.SignInWithShop[signInWithShopKey] = initFunction;
}

/**
 * Returns whether the init function was called before.
 * @param {string} signInWithShopKey The key added to window.Shopify.SignInWithShop
 * @returns {boolean} Whether the function was called before.
 */
export function getFunctionCalled(signInWithShopKey: string): boolean {
  return Boolean(
    window.Shopify?.SignInWithShop?.[`${signInWithShopKey}Called`],
  );
}

/**
 * Marks the init function as called.
 * @param {string} signInWithShopKey The key added to window.Shopify.SignInWithShop.
 */
export function setFunctionCalled(signInWithShopKey: string) {
  defineWindowSignInWithShop();
  window.Shopify.SignInWithShop[`${signInWithShopKey}Called`] = true;
}
